import React from 'react';
import Layout from '../components/layout';
import * as styles from './404.module.scss';
import '../components/layout.scss';
import SEO from '../components/seo';
import error from '../images/404/404.jpg';
import { Link } from 'gatsby';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <section>
      <div className="container text-center">
        <div>
          <img src={error} className={styles.error} />
        </div>
        <h1 className="static-h1">Page non trouvée</h1>
        <p>
          La page que vous recherchez n'existe pas, à la place vous pouvez :
        </p>
        <Link to="/" className="btn btn-primary">
          Retourner à l'accueil
        </Link>
        <Link to="/contact" className="btn btn-secondary">
          Nous contacter
        </Link>
      </div>
    </section>
  </Layout>
);

export default NotFoundPage;
